<template>
  <div>
    <div v-if="sended">
      <v-alert class="my-5" dense border="left" type="success">
        Por favor revisa tu bandeja de entrada en
        <strong>tu correo electrónico</strong> para completar el proceso.
      </v-alert>
    </div>
    <v-form v-else @submit.prevent="sendEmail()">
      <h1
        class="display-1 font-weight-bold mt-8 text-center accent--text"
      >
        Restablecer contraseña
      </h1>
      <p class="my-5 subtitle-2 font-weight-regular">
        Te enviaremos un correo electrónico con las instrucciones sobre como
        completar el proceso.
      </p>
      <v-text-field
        v-model="email"
        type="e-mail"
        label="Correo electrónico"
        class="my-1"
        prepend-icon="mdi-email"
        outlined
      ></v-text-field>
      <v-btn
        :loading="loading"
        class="my-5"
        type="submit"
        x-large
        block
        color="primary"
        depressed
      >
        Solicitar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      loading: false,
      sended: false,
    };
  },
  methods: {
    ...mapMutations(["setAlert"]),
    async sendEmail() {
      if (!this.email) {
        return false;
      }
      try {
        this.loading = true;
        await axios.post("/api/forgot-password", {
          email: this.email,
        });
        this.sended = true;
      } catch (error) {
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    // turn to lower case and remove spaces when email changes
    email: function () {
      this.email = this.email?.toLowerCase()?.replace(" ", "");
    },
  },
};
</script>
