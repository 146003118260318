<template>
  <div>
    <v-form @submit.prevent="login()">
      <h1
        class="display-1 font-weight-bold my-8 mb-10 text-center accent--text"
      >
        Iniciar sesión
      </h1>
      <v-text-field
        v-model="email"
        type="e-mail"
        label="Correo electrónico"
        class="my-1"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="password"
        label="Contraseña"
        class="my-1"
        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPass ? 'text' : 'password'"
        @click:append="showPass = !showPass"
        outlined
      ></v-text-field>
      <v-checkbox
        v-model="rememberEmail"
        label="Recordar correo electrónico"
      ></v-checkbox>
      <v-btn
        class="my-5"
        :loading="loading"
        type="submit"
        x-large
        block
        color="primary"
        depressed
      >
        Acceder
      </v-btn>
    </v-form>

    <v-dialog
      v-model="showLegalFilesToAccept"
      persistent
      @keydown.esc="showLegalFilesToAccept = false"
      width="800"
    >
      <v-card class="pa-sm-10 pa-5">
        <h5 class="title secondary--text">
          Hola {{ user ? user.firstName : "" }}
        </h5>
        <p>
          Antes de comenzar a utilizar XUMED, debes estar de acuerdo con
          nuestros
          <span
            style="text-decoration: underline; cursor: pointer"
            @click="openNewTab('/legal')"
          >
            términos y condiciones,</span
          >
          así también con nuestras
          <span
            style="text-decoration: underline; cursor: pointer"
            @click="openNewTab('/legal')"
            >políticas de privacidad</span
          >
          dentro de nuestra plataforma.
        </p>
        <v-checkbox
          v-model="termsAndConditionAccepted"
          label="He leído, comprendo y acepto las políticas de privacidad y los
          términos y condiciones dentro de la plataforma"
        ></v-checkbox>
        <v-row>
          <v-col cols="12" md="6"></v-col>
        </v-row>
        <div class="d-md-flex">
          <v-btn
            :loading="loading"
            :disabled="!termsAndConditionAccepted"
            @click="acceptTerms"
            depressed
            color="primary"
            class="mr-3 mt-5"
            >Continuar</v-btn
          >
          <v-btn
            class="mt-5 d-none d-md-flex"
            :loading="loading"
            depressed
            @click="sendToEmail"
            >Enviar al correo electrónico</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "LoginForm",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      showPass: false,
      termsAndConditionAccepted: false,
      showLegalFilesToAccept: false,
      rememberEmail: false,
      firstName: "",
      user: null,
    };
  },
  methods: {
    ...mapMutations(["setAlert", "setCurrentUser"]),
    async acceptTerms() {
      try {
        this.loading = true;
        const res = await axios.post("/api/user/terms/accepted");
        if (res) {
          this.handleLoginSuccess();
        }
      } catch (error) {
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async sendToEmail() {
      try {
        this.loading = true;
        const res = await axios.post("/api/user/terms/send");
        if (res) {
          await axios.get("/api/logout");
          this.setCurrentUser(null);

          const { data } = res;
          this.setAlert({
            show: true,
            color: "success",
            icon: "mdi-check-circle",
            text: data.message,
            timeout: 4000,
          });
          this.password = "";
          this.showLegalFilesToAccept = false;
        }
      } catch (error) {
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async login() {
      if (!this.email || !this.password) {
        return false;
      }
      try {
        this.loading = true;
        const res = await axios.post("/api/login", {
          email: this.email,
          password: this.password,
        });
        const { user } = res.data;
        this.user = user;

        if (this.user.termsAccepted) {
          this.setAlert({
            show: true,
            color: "success",
            icon: "mdi-hand-wave",
            text: "Hola " + this.user?.firstName,
            timeout: 4000,
          });
          this.handleLoginSuccess();
        } else {
          this.firstName = user.firstName;
          this.showLegalFilesToAccept = true;
        }
      } catch (error) {
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async handleLoginSuccess() {
      if (this.rememberEmail) {
        localStorage.setItem("xmd-login-email", this.email);
      } else {
        localStorage.removeItem("xmd-login-email");
      }

      if (this.user?.role === "admin") this.$router.push("/doctores");
      else this.$router.push("/agenda");
    },
    openNewTab(path) {
      // Specify the URL for the new tab
      const newTabUrl = path; // Change this to the desired path

      // Create an anchor element
      const anchor = document.createElement("a");

      // Set the href attribute to the desired URL
      anchor.href = newTabUrl;

      // Set the target attribute to _blank to open in a new tab
      anchor.target = "_blank";

      // Trigger a click event on the anchor element
      anchor.click();
    },
  },
  watch: {
    // turn to lower case and remove spaces when email changes
    email: function () {
      this.email = this.email?.toLowerCase()?.replace(" ", "");
    },
  },
  created() {
    this.email = localStorage.getItem("xmd-login-email");
    if (this.email) {
      this.rememberEmail = true;
    }
  },
};
</script>
