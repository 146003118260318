<template>
  <div style="height: 100%" class="d-flex justify-center align-center">
    <div class="pb-10">
      <div class="login-container">
        <div v-if="!showForgotPasswordForm">
          <transition name="slide-fade" mode="out-in">
            <LoginForm />
          </transition>
        </div>
        <div v-else>
          <transition name="slide-fade" mode="out-in">
            <ForgotPassword />
          </transition>
        </div>
        <div class="d-flex justify-center text-center mt-10">
          <div>
            <v-btn text no-cap small block color="secondary" to="/registro">
              <span style="text-transform: none; text-decoration: underline"
                >Si no tienes una cuenta, regístrate</span
              >
            </v-btn>
            <v-btn
              text
              no-cap
              small
              color="secondary"
              class="mt-1"
              @click="showForgotPasswordForm = !showForgotPasswordForm"
            >
              <span style="text-transform: none">{{
                showForgotPasswordForm ? "Volver" : "¿Olvidaste tu contraseña?"
              }}</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/Auth/LoginForm";
import ForgotPassword from "@/components/Auth/ForgotPassword.vue";
import MainLayout from "../components/Layouts/MainLayout.vue";

export default {
  name: "Login",
  components: {
    LoginForm,
    ForgotPassword,
    MainLayout,
  },
  data() {
    return {
      showForgotPasswordForm: false,
    };
  },
};
</script>

<style scoped>
.login-container {
  width: 500px;
  padding-top: 50px;
}

@media only screen and (max-width: 768px) {
  .login-container {
    width: auto;
    padding-top: 25px;
  }
}

.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
